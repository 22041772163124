import IMask from 'imask';

// add custom js below

let toggle = document.querySelector('.toggle input');
if(document.body.clientWidth<1025){
  $('header .menu-button, .nav a').on('click', (e) => {
    // console.log('111');
    $('header').toggleClass('menu-hide');
    $('header').toggleClass('menu-show');
    toggle.checked = !toggle.checked;
});
}   


var element = document.querySelector('.input__phone');
console.log(element);
var maskOptions = {
  mask: '+{7} (000) 000-00-00',
  lazy: true,  // make placeholder always visible
  placeholderChar: '_'     // defaults to '_'
};
var mask = IMask(element, maskOptions);

var phone_validate=false;
mask.on("complete", function () {phone_validate=true});
var form_submit = document.querySelector('.callback form');

form_submit.addEventListener("submit", function(event){
  event.preventDefault();
  if(phone_validate) console.log('телефон заполнен');
  else console.log('телефон не заполнен');
});